<template>
  <!--
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ]
  }
  ```
-->
  <div class="flex items-center justify-center min-h-screen px-4 py-12 bg-gray-50 sm:px-6 lg:px-8">
    <div class="w-full max-w-md space-y-8">
      <div>
        <!-- <img
          class="w-auto h-12 mx-auto"
          src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
          alt="Workflow"
        /> -->
        <div class="flex justify-items-center">
          <w-logo-orsai class="w-64 mx-auto text-gray-900" />
        </div>
        <!-- <h2 class="mt-6 text-3xl font-extrabold text-center text-gray-900">
          Administrador Orsai
        </h2> -->
        <!-- <p class="mt-2 text-sm text-center text-gray-600">
          Or
          <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500">
            start your 14-day free trial
          </a>
        </p> -->
      </div>
      <form class="mt-8 space-y-6" action="#" method="POST" @submit.prevent="login()">
        <input type="hidden" name="remember" value="true" />
        <div class="-space-y-px rounded-md shadow-sm">
          <div>
            <label for="email-address" class="sr-only">Correo Electrónico</label>
            <input
              v-model="user.email"
              id="email-address"
              name="email"
              type="email"
              required
              class="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Correo Electrónico"
            />
          </div>
          <div>
            <label for="password" class="sr-only">Contraseña</label>
            <input
              v-model="user.password"
              id="password"
              name="password"
              type="password"
              required
              class="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Contraseña"
            />
          </div>
        </div>

        <div class="flex items-center justify-between">
          <div class="flex items-center">
            <input
              id="remember_me"
              type="checkbox"
              checked
              class="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
            />
            <label for="remember_me" class="block ml-2 text-sm text-gray-900"> Recordarme </label>
          </div>

          <div v-if="false" class="text-sm">
            <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500">
              ¿Olvidaste tu contraseña?
            </a>
          </div>
          <div v-if="error" class="text-sm">
            <a href="#" class="font-medium text-red-600"> Los datos son incorrectos </a>
          </div>
        </div>

        <div>
          <div class="font-medium text-red-500"></div>
          <button
            type="submit"
            class="relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md group hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <span class="absolute inset-y-0 left-0 flex items-center pl-3">
              <!-- Heroicon name: lock-closed -->
              <svg
                v-if="!busy"
                class="w-5 h-5 text-indigo-500 group-hover:text-indigo-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                  clip-rule="evenodd"
                />
              </svg>

              <svg
                v-if="busy"
                class="w-5 h-5 ml-1 mr-2 text-white transition duration-150 ease-in-out animate-spin"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-50"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </span>
            Ingresar
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  layout: "login",
  data() {
    return {
      busy: false,
      error: false,
      user: {
        // email:
        //   process.env.NODE_ENV == "production"
        //     ? ""
        //     : "admin@hernancasciari.com",
        // password: process.env.NODE_ENV == "production" ? "" : "VUIz198BES",
        email: process.env.NODE_ENV == "production" ? "" : "lizy@orsai.org",
        password: process.env.NODE_ENV == "production" ? "" : "Panama",
      },
    };
  },
  methods: {
    async login() {
      try {
        this.busy = true;
        this.error = true;
        let { user } = await this.$store.dispatch("auth/authenticate", {
          strategy: "local",
          email: this.user.email,
          password: this.user.password,
        });
        console.log(user);

        if (!user.passwordUpdated) {
          this.$router.push("/password");
        } else {
          if (user.isBranch) this.$router.push("/food-orders");
          else if (user.isBranchAdmin) this.$router.push("/food-orders");
          else if (user.isAdmin) this.$router.push("/shipments?q=all");
          else if (user.isOwner) this.$router.push("/estadisticas");
          else if (user.permissions && user.permissions.includes("eventos")) {
            this.$router.push("/eventos");
          } else {
            this.$router.push("/products");
          }

          this.busy = false;
          console.log(user);
        }
      } catch (e) {
        this.busy = false;
        this.error = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
